<template>
  <div>
    <div class="modal_header">
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              <h5>{{ $t("message.reports") }}</h5>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <!-- <export-excel class="btn excel_btn" :data="model.partnerdoctorreport_order_list" :fields="excel_fields" @fetch="controlExcelData()"
                                worksheet="Заявки" name="Заявки.xls">
                                <el-button size="mini" icon="el-icon-document-delete"> Excel </el-button>
                            </export-excel> -->
              <el-button @click="resetForm()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="modal-doctoe-info">
      <el-row :gutter="10" class="credit_row">
        <el-col :span="5"
          >zz
          <div class="font__W">{{ $t("message.ifo") }}</div>
          <div>{{ model.name }} {{ model.surname }}</div>
        </el-col>
        <el-col :span="5">
          <div class="font__W">Колесностьова</div>
          <div>{{ this.model.partnerdoctorreport_order_list.data.length }}</div>
        </el-col>

        <el-col :span="5">
          <div class="font__W">{{ $t("message.totals") }}</div>
          <div>{{ total_prices | formatMoney }}</div>
        </el-col>
      </el-row>

      <div class="text-center d-flex sorddata mt-3">
        <el-date-picker
          size="mini"
          v-model="filterForm.orders_from"
          type="date"
          :format="'dd.MM.yyyy'"
          :value-format="'dd.MM.yyyy'"
          :placeholder="$t('message.start_date')"
        ></el-date-picker>
        <el-date-picker
          size="mini"
          v-model="filterForm.orders_to"
          type="date"
          :format="'dd.MM.yyyy'"
          :value-format="'dd.MM.yyyy'"
          :placeholder="$t('message.end_date')"
        ></el-date-picker>
      </div>

      <table
        class="table table-hover table-bordered mt-3 table__cerditne__forma"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="this.model.partnerdoctorreport_order_list.pagination"
          @c-change="updateOrderListPagination"
        ></crm-pagination>
        <thead>
          <tr>
            <th>#</th>
            <th scope="col">{{ $t("message.ifo") }}</th>
            <th scope="col">{{ $t("message.date_0") }}</th>
            <!-- <th scope="col">Пеня дней</th>
                        <th scope="col">Пеня процентов</th> -->
            <th scope="col">{{ $t("message.not_paid") }}</th>
            <th scope="col">{{ $t("message.paid") }}</th>
            <th scope="col">{{ $t("message.sum") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in model.partnerdoctorreport_order_list.data"
            :key="index"
          >
            <td>{{ item.id }}</td>
            <td>
              {{
                item.patient
                  ? item.patient.first_name + " " + item.patient.surname
                  : ""
              }}
            </td>
            <td>{{ item.created_at }}</td>
            <!-- <td>0 сум</td>
                        <td>0 %</td> -->
            <td>{{ item.not_paid | formatMoney }}</td>
            <td>{{ (item.total_price - item.not_paid) | formatMoney }}</td>
            <td>
              <span class="act_color">{{
                item.total_price | formatMoney
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  props: ["selected"],
  data() {
    return {
      filterForm: {
        orders_from: "",
        orders_to: "",
      },
      loadingData: false,

      // excel_fields: {
      //     'Пациент': {
      //         callback: (val) => {
      //             return val.patient.surname + ' ' + val.patient.first_name
      //         }
      //     },
      //     'Дата': 'created_at',
      //     'Не оплачено': 'not_paid' ,
      //     'Оплачено':  'total_price' ,
      //     'Сумма':  'total_price' ,
      //     'Доктор': {
      //         callback: (value) => {
      //             return this.model.surname + ' ' + this.model.name + ' ' + this.model.patronymic;
      //         }
      //     }
      // },
    };
  },
  computed: {
    ...mapGetters({
      model: "partnerDoctorReport/model",
      pagination: "partnerDoctorReport/pagination",
    }),
    no_prices: function () {
      let orders = this.model.partnerdoctorreport_order_list.data;
      let prices = 0;
      for (let i = 0; i < orders.length; i++) {
        const element = orders[i];
        prices += element.not_paid;
      }
      return prices;
    },
    paying_prices: function () {
      let orders = this.model.partnerdoctorreport_order_list.data;
      let prices = 0;
      for (let i = 0; i < orders.length; i++) {
        const element = orders[i];
        prices += element.total_price - element.not_paid;
      }
      return prices;
    },
    total_prices: function () {
      let orders = this.model.partnerdoctorreport_order_list.data;
      let prices = 0;
      for (let i = 0; i < orders.length; i++) {
        const element = orders[i];
        prices += element.total_price;
      }
      return prices;
    },
  },

  watch: {
    filterForm: {
      handler: function (newVal, oldVal) {
        this.showReport();
      },
      deep: true,
      immediate: true,
    },
  },

  created() {},

  methods: {
    updateOrderListPagination(pagination) {
      this.model.partnerdoctorreport_order_list.pagination[pagination.key] =
        pagination.value;
      this.loadingData = true;
      this.showModel({
        id: this.model.id,
        orders_from: this.filterForm.orders_from,
        orders_to: this.filterForm.orders_to,
        [pagination.key]: pagination.value,
      })
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
          this.$alert(err);
        });
    },
    ...mapActions({
      showModel: "partnerDoctorReport/show",
    }),

    showReport() {
      this.loadingData = true;
      this.showModel({
        id: this.model.id,
        orders_from: this.filterForm.orders_from,
        orders_to: this.filterForm.orders_to,
      })
        .then((res) => {
          this.showModel();
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    afterOpen() {
      this.loadingData = true;
      this.showModel({
        id: this.selected.id,
        orders_from: this.filterForm.orders_from,
        orders_to: this.filterForm.orders_to,
      })
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    // controlExcelData(){
    //     // this.excel_fields = {
    //     //     'И.Ф.О': id,
    //     //     'Дата': created_at,
    //     //     'Не оплачено': not_paid ,
    //     //     'Оплачено':  total_price ,
    //     //     'Сумма':  total_price ,
    //     // };

    //     // for (let key in this.columns){
    //     //     if (this.columns.hasOwnProperty(key)){
    //     //         let column = this.columns[key];
    //     //         if (column.show && column.column !== 'settings'){
    //     //             this.excel_fields[column.title] = column.column;
    //     //         }
    //     //     }
    //     // }
    // },
    resetForm() {
      this.$emit("c-close", { drawer: "drawer" });
    },
  },
};
</script>
<style lang="scss">
.modal-doctoe-info {
  margin: 20px 30px;
}
.credit_row {
  margin-left: 10px;
  margin-right: -10px;
  text-align: center;
  background-color: #e4e7ed;
  padding: 10px 0;
  .font__W {
    font-weight: 600;
    font-size: 15px;
  }
  .color_or {
    background: #ffbb58;
    color: #fff;
    padding: 2px 7px;
    border-radius: 10px;
  }
  div {
    font-size: 12px;
  }
}
.act_color {
  background-color: #67c23a;
  color: #fff;
  padding: 2px 7px;
  border-radius: 10px;
}
.not-paid {
  border: 1px solid #dee2e6;
  padding: 1px 4px;
  border-radius: 10px;
  background-color: #fff;
}
.watch--story {
  padding: 7px 8px;
  background-color: #409eff;
  color: #fff;
}
.table__cerditne__forma {
  th {
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 14px !important;
  }
  th,
  td {
    padding: 0.55rem !important;
  }
  .action_tr {
    background: #ffbb58;
  }
}
</style>